export default {
    initialize(naja) {
        naja.uiHandler.addEventListener('interaction', (event) => {
            if (event.detail.element instanceof HTMLElement) {
                const form = event.detail.element.closest('form')
                if (form instanceof HTMLFormElement) {
                    form.classList.add('was-validated')
                }
            }
        })
    }
}
