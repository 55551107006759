export default {
    initialize(naja) {
        naja.addEventListener('start', (event) => {
            if (event.explicitOriginalTarget) {
                const submit = event.explicitOriginalTarget
                if (submit.tagName === 'FORM') {
                    submit.classList.add('loading')
                } else {
                    const form = submit.closest('form')
                    if (form instanceof HTMLFormElement) {
                        form.classList.add('loading')
                    }
                }
            }
        })

        naja.addEventListener('complete', (event) => {
            document.querySelectorAll('form.loading').forEach((form) => {
                form.classList.remove('loading')
            })
        })
    }
}
