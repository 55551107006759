import 'es-module-shims'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'instant.page'
import 'iconify-icon'
import netteForms from 'nette-forms'

import { onDocumentReady } from './src/onDocumentReady'
import naja from 'naja'
import LazyLoad from 'vanilla-lazyload'
import { stickyHeader } from './src/stickyHeader'
import videoLoader from './src/videoLoader'
import initMenu from './src/megaMenu'
import formLoaderExtension from './src/formLoader.naja.ext'
import formValidationExtension from './src/formValidation.naja.ext'

netteForms.initOnLoad()

onDocumentReady(() => {
    document.body.classList.add('site-onready')

    naja.registerExtension(formLoaderExtension)
    naja.registerExtension(formValidationExtension)
    naja.formsHandler.netteForms = netteForms
    naja.initialize()

    const cocoboxScripts = {
        lazyLoadInstance: new LazyLoad({})
    }
    cocoboxScripts.lazyLoadInstance.update()
    window.cocoboxScripts = cocoboxScripts

    document.getElementById('__navbarToggler').addEventListener('click', () => {
        if (document.body.classList.contains('mobile-menu-open')) {
            document.body.classList.remove('mobile-menu-open')
            return
        }
        document.body.classList.toggle('mobile-menu-open')
    })

    stickyHeader()
    videoLoader()
    initMenu()
})
